<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="大区事务所">
                <a-cascader
                  :options="dealerList"
                  v-model="regionalOffice"
                  change-on-select
                  placeholder="请选择大区/事务所"
                  @change="onChange"
                  :field-names="{ label: 'title', value: 'id', children: 'children' }"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="经销商">
                <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="对账人">
                <a-input v-model="searchData.statementUser" placeholder="请输入对账人" allowClear></a-input>
              </a-form-model-item>
            </a-col>
<!--            <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--              <a-form-model-item label="对账状态">-->
<!--                <a-select placeholder="请选择" v-model="searchData.confirmStatus" allowClear>-->
<!--                  <a-select-option :value="0">未确认</a-select-option>-->
<!--                  <a-select-option :value="1">相符</a-select-option>-->
<!--                  <a-select-option :value="2">不相符</a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-model-item>-->
<!--            </a-col>-->
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
<!--              <a-form-model-item label="对账时间">-->
<!--                <a-month-picker placeholder="请选择对账时间" />-->
<!--              </a-form-model-item>-->
              <a-form-model-item label="对账月份">
                <MonthPicker :startTime.sync="searchData.checkTime" />
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="query()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div>
          <a-button v-if="isCheck" type="primary" @click="hanlderTest('check')"> 查看 </a-button>
          <a-button v-if="isDeal" type="primary" @click="hanlderTest('toDeal')">处理</a-button>
<!--          <downLoad-->
<!--            method="get"-->
<!--            api="/api/order/order/orderInfo/exportSingleOrderInfo"-->
<!--            :params="{ id: 11 }"-->
<!--            name="导出.xlsx"-->
<!--            >导出</downLoad-->
<!--          >-->
        </div>
        <!-- 数据表格 -->
        <a-table
          :loading="tableLoading"
          :row-selection="rowSelection"
          :rowKey="(record) => record.id"
          :columns="columns"
          :data-source="tableData"
          bordered
          :customRow="changeTableRow"
          @change="changeTable"
          :pagination="page"
        >
          <!-- :pagination="page" -->
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="confirmStatus" slot-scope="text,record">
            <a-tag color="#e93323" v-if="text == 0">{{record | formatterStatus}}</a-tag>
            <a-tag color="#67c23a" v-if="text == 1">相符</a-tag>
            <a-tag color="#e6a23c" v-if="text == 2">不相符</a-tag>
          </span>
          <span slot="auditStatus" slot-scope="text,record">
            <a-tag v-if="text == 0" color="red">{{record | formatterAudit}}</a-tag>
            <a-tag v-if="text == 1" color="pink">待审核</a-tag>
            <a-tag v-if="text == 2" color="green">审核通过</a-tag>
            <a-tag v-if="text == 3" color="orange">审核拒绝</a-tag>
          </span>
          <span slot="oweAmount" slot-scope="text,record">
            <span class="TextRed" @click="handlerTableClick('TextRed',record)">{{ text | formatMoney }}</span>
          </span>
          <span slot="totalAmount" slot-scope="text">
            <span class="DarkGreen">{{ text | formatMoney }}</span>
          </span>
          <span slot="balanceAmount" slot-scope="text,record">
            <span class="ShallowGreen" @click="handlerTableClick('ShallowGreen',record)">{{ text | formatMoney }}</span>
          </span>
          <span slot="limitAmount" slot-scope="text,record">
            <span class="ShallowGreen" @click="handlerTableClick('ShallowGreenXY',record)">{{ text | formatMoney }}</span>
          </span>
          <span slot="CKDZD" slot-scope="text,record">
            <span class="DarkBule" @click="handlerTableClick('DarkBule',record)">对账单</span>
          </span>
          <div slot="statementFile" slot-scope="text" class="img-list">
            <div v-if="text" class="img-box">
              <img
                class="img-url"
                v-if="isImgFile(text)"
                width="100%"
                height="100%"
                :src="text"
                alt="无账单附件"
                @click="handlerEnlarge(text)"
              />
              <a v-else :href="text" target="_blank" class="buttonText">查看附件</a>
            </div>
          </div>
        </a-table>
      </div>
      <Overview ref="overview" />
      <ZJAccountInfo ref="ZJAccountInfo" />
      <XYAccountInfo ref="XYAccountInfo" />
      <QKAccountInfo ref="QKAccountInfo" />
      <!-- 查看详情 -->
      <AccountCheckModal ref="accountCheckModal" />
      <Drawer ref="Drawer" />

      <a-modal
        title="处理不相符账单"
        width="30%"
        :visible="dealVisible"
        :footer="null"
        @cancel="dealVisible = false"
        :maskClosable="false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="处理说明">
            <a-textarea v-model="rowDetail.statementRemark"></a-textarea>
          </a-form-model-item>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="dealVisible = false">取消</a-button>
          <a-button type="primary" @click="handleDeal">确认</a-button>
        </div>
      </a-modal>

    </a-card>
  </a-spin>
</template>

<script>
import { listDealerStatement } from './api/DealerStatementApi'
import { PAYMENT_TEMP } from '@/utils/downloadTempHref'
import {uncolumns} from './thead/colums'
import { mixin } from './components/mixin/insert'
import {checkPermission} from "@/utils/permissions";
// 查看详情
export default {
  name: 'financeReceipt',
  mixins: [mixin],
  components: {
    AccountCheckModal: () => import('./components/accountCheckModal.vue'),
    Overview: () => import('./components/table/overview.vue'),
    ZJAccountInfo: () => import('./components/info/ZJAccountInfo.vue'),
    XYAccountInfo: () => import('./components/info/XYAccountInfo.vue'),
    QKAccountInfo: () => import('./components/info/QKAccountInfo.vue'),
    Drawer: () => import('./components/handler/drawer.vue'),
  },
  data() {
    return {
      columns: uncolumns,
      tableData: [],
      tableLoading: false, //表格loading
      loading: false,
      downloadTempHref: PAYMENT_TEMP,
      spinning: false,
      dealVisible:false,
      rowDetail:{},
      isCheck: checkPermission('unMatch:statement:list:check'),
      isDeal: checkPermission('unMatch:statement:list:deal'),
    }
  },
  filters: {
    formatterStatus: function(row) {
      if (new Date(row.finalTime).getTime() < new Date().getTime()) {
        return '已逾期';
      } else {
        return '待确认';
      }
    },
    formatterAudit: function (row) {
      if (row.confirmStatus == 2) {
        return '待处理';
      } else {
        return '待确认';
      }
    }
  },
  methods: {
    //  附件抽屉
    handlerEnlarge(data) {
      // this.isShowCarousel = false
      if (this.$refs.Drawer) {
        this.$refs.Drawer.init(data)
      }
    },
    handlerTableClick(type,row) {
      if (type === 'TextRed') {
        if (this.$refs.QKAccountInfo) {
          this.$refs.QKAccountInfo.init(row)
        }
      }
      if (type === 'ShallowGreen') {
        if (this.$refs.ZJAccountInfo) {
          this.$refs.ZJAccountInfo.init(row)
        }
      }
      if (type === 'ShallowGreenXY') {
        if (this.$refs.XYAccountInfo) {
          this.$refs.XYAccountInfo.init(row)
        }
      }
      if (type === 'DarkBule') {
        if (this.$refs.overview) {
          this.$refs.overview.setRowData(row)
        }
      }
    },
    hanlderTest(name) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        return _this.$message.warning('请选择一条记录')
      }
      if (name === 'check') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.accountCheckModal.setRowData(_this.selectedRows[0], 'check')
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'confirm') {
        if (_this.$refs.confirmDom) {
          _this.$refs.confirmDom.init('confirm')
        }
        return
      }
      if (name === 'toDeal') {
        const everyResult = this.selectedRows.every((item) => {
          return item.flagConfirm && item.confirmStatus == 2
        })
        if (everyResult) {
          this.dealVisible = true
          const vo = []
          this.selectedRows.forEach((e) => {
            vo.push(e.id)
          })
          this.rowDetail.statementIds = vo
          return
        } else {
          this.$message.warning('请选择已确认不相符的对账单')
        }
      }

      if (name === 'rollbackStatus') {
        _this.$confirm({
          title: '撤审警告',
          content: '确认要撤回此条记录的审核吗?',
          okText: '确认',
          cancelText: '取消',
        })
        return
      }
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      this.searchData.flagConfirm = true
      this.searchData.confirmStatus = 2
      listDealerStatement({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },

    // 处理不相符账单
    handleDeal() {
      if (this.rowDetail.statementRemark != null && this.rowDetail.statementRemark != '') {
        this.tableLoading = true
        this.axios.post('/api/order/statements/dealerStatements/dealUnMatchStatement', this.rowDetail).then(res => {
          if (res.code == 200) {
            this.dealVisible = false
            this.rowDetail = {}
            this.$message.success(res.message)
            this.getData()
          } else {
            this.$message.error(res.message)
            this.getData()
          }
          this.tableLoading = false
        })
      }else {
        this.$message.warning('请先填写处理说明！')
      }
    },
    isImgFile(fileName) {
      console.log(fileName, 'fileName')
      //后缀获取
      let suffix = '';
      // 获取类型结果
      let result = '';
      const flieArr = fileName.split('.');
      suffix = flieArr[flieArr.length - 1];
      if (suffix != '') {
        suffix = suffix.toLocaleLowerCase();
        // 图片格式
        const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
        // 进行图片匹配
        result = imglist.find(item => item === suffix);
        if (result) {
          return true;
        }
      }
      return false;
    },

  },
}
</script>

<style lang="less" scoped>
@import "./style/main.less";
.img-box {
  width: 80px;
  height: 80px;
}
.img-url {
  object-fit: cover;
}
</style>
